import {createRouter, createWebHistory} from 'vue-router'
import { generateRoutes } from '@/extensions/langRouter'

const NotFound = () => import('@/views/NotFound')

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('@/views/Main'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/Home')
            },
            {
                path: '/activites',
                paths: {en: '/events'},
                name: 'events',
                component: () => import('@/views/Events')
            },
            {
                path: '/boire-et-manger',
                paths: {en: '/drink-and-eat'},
                name: 'about',
                component: () => import('@/views/About')
            },
        ]
    },
    {
        path: '/activites/:slug',
        paths: {en: '/events/:slug'},
        name: 'event',
        component: () => import('@/views/Event')
    },
    {
        path: '/sites/:slug',
        name: 'site',
        component: () => import('@/views/Site')
    },
    {
        path: '/politique',
        name: 'policy',
        component: () => import('@/views/Policy')
    },
    {
        path: '/404',
        name: 'not-found',
        component: NotFound
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'catch-all',
        component: NotFound
    }
]

const localizedRoutes = generateRoutes(routes, 'fr', 'en')

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: '-active',
    linkExactActiveClass: '-exact-active',
    routes: localizedRoutes
})

export default router
