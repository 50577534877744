<template>
  <div class="footer">
    <section class="footer__section">
      <img class="footer__logo" src="/img/logo_2024.png" width="225" height="118" alt="logo"/>

      <h2 class="section__title"> {{ $t('infoTitle') }} </h2>
      <p class="section__p"> {{ $t('infoText') }} </p>
      <div class="footer__subscribe">
        <input :placeholder="$t('yourEmail')" class="input-text" type="text" v-model="email"/>
        <button class="button -red footer__subscribe-button" @click="newsletter"> {{ $t('subscribe') }} </button>
        <transition name="fade" appear>
          <div v-if="sent" class="footer__subscribe-confirm">
            {{ $t('newsletterConfirm') }}
          </div>
        </transition>
      </div>


    </section>

    <section class="footer__section -between">
      <div class="footer__socials">
        <a :href="socials.facebook" target="_blank" v-if="socials.facebook">
          <Icon id="facebook"/>
        </a>

        <a :href="socials.instagram" target="_blank" v-if="socials.instagram">
          <Icon id="instagram"/>
        </a>

        <a :href="socials.linkedin" target="_blank" v-if="socials.linkedin">
          <Icon id="linkedin"/>
        </a>

        <a :href="socials.twitter" target="_blank" v-if="socials.twitter">
          <Icon id="twitter"/>
        </a>

        <a :href="socials.youtube" target="_blank" v-if="socials.youtube">
          <Icon id="youtube"/>
        </a>
      </div>

      <div class="footer__card">
        <h3 class="footer__card-title">{{ $t('toWatch') }}</h3>
        <LangRouterLink :to="{name: 'event', params: {slug: i.activity.slug}}" class="footer__card-line" v-for="i in activities" :key="i.activity.id">
          <div><Icon id="star"/></div>
          <div>{{ i.label }}</div>
        </LangRouterLink>
        <Bubble class="footer__bubble" :text="socials.bubbleText"/>
      </div>
    </section>
  </div>

  <footer class="footer__bottom" v-if="$beet.options.footer">
    <div>© {{ $beet.options.footer.copyright.text }}<br v-if="!mq.mdPlus"/> <LangRouterLink :to="{name: 'policy'}" class="footer__bottom-policy">{{ $beet.options.footer.copyright.confidentiality }}</LangRouterLink></div>
    <div> Site conçu et développé par <a href="https://boitebeet.com/" target="_blank">Beet</a></div>
  </footer>
</template>

<script>
import Bubble from "@/components/Bubble.vue";
import LangRouterLink from "@/components/global/LangRouterLink.vue";
import http from "@/extensions/http/index.js";

export default {
  name: "SiteFooter",
  components: {LangRouterLink, Bubble},
  inject: ['mq'],
  data() {
    return {
      email: '',
      sent: false
    }
  },
  computed: {
    socials() {
      return this.$beet.options?.contact?.social || {}
    },
    activities() {
      if(!this.$beet.options.footer) return []
      return this.$beet.options.footer.activities.map(activity => {
        return {
          activity: this.$beet.activities[Number(activity.activity)],
          label: activity.title
        }
      }, []) || []
    }
  },
  methods: {
    newsletter() {
      this.$recaptcha().then(() => {
        http.post('/newsletter', {email: this.email}).then(() => {
          this.email = ''
          this.sent = true
          setTimeout(() => {
            this.sent = false
          }, 5000)
        }).catch((err) => {
          console.log(err)
        })
      })
    }
  }
}
</script>
