import './startup'

import {createApp} from 'vue'
import { Vue3Mq } from "vue3-mq";
import mitt from 'mitt';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import GlobalComponents from '@/components/global'
import BeetAPI from '@boite-beet/api-client'
import langRouter from '@/extensions/langRouter'
import reveal from '@/extensions/reveal'
import { setupCalendar, DatePicker, Calendar } from 'v-calendar';
import Popper from "vue3-popper";

import '@/styles/app.scss'
import 'swiper/css';

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(langRouter, 'fr')
    .use(GlobalComponents)
    .use(BeetAPI, {store, apiUrl: process.env.VUE_APP_API_URL})
    .use(reveal)
    .use(setupCalendar, {})
    .use(Vue3Mq, {
        breakpoints: {
            sm: 450,
            md: 600,
            lg: 1200,
            xl: 1440
        }
    })

const emitter = mitt();
app.provide('emitter', emitter)

app.component('DatePicker', DatePicker)
app.component('calendar', Calendar)
app.component("Popper", Popper);

import { createGtm } from '@gtm-support/vue-gtm';
try {
    app.use(
        createGtm({
            id: process.env.VUE_APP_GTAG_ID,
            vueRouter: router,
            enabled: false
        })
    )
} catch (error) {
    console.warn(error)
}

import {VueReCaptcha} from "vue-recaptcha-v3";
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

app.mount('#app')
