<template>
  <div class="navbar" v-if="$beet.options.menus">
     <LangRouterLink :to="{name: 'home'}" class="button -shadow -nav" :class="{'-inverse': ['home', 'site', 'home:en', 'site:en'].includes($route.name)}"> {{ $beet.options.menus.home.quickLook }} </LangRouterLink>
     <LangRouterLink :to="{name: 'events'}" class="button -shadow -nav" :class="{'-inverse': ['events', 'event', 'events:en', 'event:en'].includes($route.name)}"> {{ $beet.options.menus.home.allActivities }} </LangRouterLink>
     <LangRouterLink :to="{name: 'about'}" class="button -shadow -nav" :class="{'-inverse': ['about', 'about:en'].includes($route.name)}"> {{ $beet.options.menus.home.eatDrink }} </LangRouterLink>
  </div>
</template>

<script>

export default {
name: "SiteNav",
  computed: {
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    }
  }
}
</script>
