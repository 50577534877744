<template>
  <div class="header">
    <section class="header__section" v-if="mq.mdPlus && $beet.options.menus">
      <a :href="`https://carnaval.qc.ca/${$i18n.locale === 'en' ? 'en' : ''}`" class="header__section-back button -back " :class="{'-round' : mq.lgMinus}">
        <icon id="arrow-back" :class="{'button__icon' : !mq.lgMinus}"/>  <span v-if="!mq.lgMinus"> {{ $beet.options.menus.menu.backToSite }} </span>
      </a>
      <a :href="pdf" target="_blank" class="button" v-if="mq.lgPlus && pdf"> <Icon id="pdf" class="button__icon"/> {{ $beet.options.menus.menu.pdfVersion || '' }} </a>
    </section>

    <section class="header__section -center">
      <LangRouterLink :to="{name: 'home'}" class="header__logo" :style="{backgroundImage: `url(/img/logo_2024.png)`}"/>
    </section>

    <section class="header__section" v-if=" $beet.options.menus">
      <a href="https://lepointdevente.com/billets/car240211001" target="_blank" class="button" v-if="mq.lgPlus"> <img src="/img/icones/Bonhomme.svg" width="23" height="23" alt="bh" class="button__icon--bonhomme"/> {{ $beet.options.menus.menu.buyEffigy }} </a>
      <button class="button -round" @click="$i18n.locale = otherLang.toLowerCase()"> {{ otherLang }} </button>
      <button class="header__burger" @click="menuOpen = !menuOpen" v-if="mq.mdMinus">
        <transition name="fade-fast" mode="out-in">
          <Icon :id="menuOpen ? `burger-close` : `burger`" :key="menuOpen ? `burger-close` : `burger`" />
        </transition>
      </button>
    </section>

  </div>
    <div class="header__menu" :class="{'-open' : menuOpen}" v-if="$beet.options?.menus">
      <div class="header__menu__buttons">
        <a :href="pdf" target="_blank" class="button" v-if="pdf">
          PDF
          <Icon id="download" style="margin-left: 0.5rem;"/>
        </a>

        <a href="https://carnaval.qc.ca/boutique/" target="_blank" class="button"> <img src="/img/icones/Bonhomme.svg" width="20" height="20" alt="bh" class="button__icon--bonhomme -sm"/> Acheter votre effigie </a>
      </div>

      <h3 class="header__menu__subtitle">
        programmation
      </h3>

      <div class="header__menu__links">
        <LangRouterLink @click="menuClick" :to="{name: 'home'}" class="header__menu__link" :class="{'-inverse': ['home', 'site', 'home:en', 'site:en'].includes($route.name)}"> {{ $beet.options.menus.home.quickLook }} </LangRouterLink>
        <LangRouterLink @click="menuClick" :to="{name: 'events'}" class="header__menu__link" :class="{'-inverse': ['events', 'event', 'events:en', 'event:en'].includes($route.name)}"> {{ $beet.options.menus.home.allActivities }} </LangRouterLink>
        <LangRouterLink @click="menuClick" :to="{name: 'about'}" class="header__menu__link" :class="{'-inverse': ['about', 'about:en'].includes($route.name)}"> {{ $beet.options.menus.home.eatDrink }} </LangRouterLink>
        <div class="header__menu__link" @click="scrollToBottom">À surveiller</div>
      </div>

      <div class="header__menu__socials">
          <a :href="socials.facebook" target="_blank" v-if="socials.facebook">
            <Icon id="facebook"/>
          </a>

          <a :href="socials.instagram" target="_blank" v-if="socials.instagram">
            <Icon id="instagram"/>
          </a>

          <a :href="socials.linkedin" target="_blank" v-if="socials.linkedin">
            <Icon id="linkedin"/>
          </a>

          <a :href="socials.twitter" target="_blank" v-if="socials.twitter">
            <Icon id="twitter"/>
          </a>

          <a :href="socials.youtube" target="_blank" v-if="socials.youtube">
            <Icon id="youtube"/>
          </a>
      </div>

    </div>
</template>

<script>
import LangRouterLink from "@/components/global/LangRouterLink.vue";

export default {
  name: "SiteHeader",
  components: { LangRouterLink},
  inject: ['mq'],
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    menuClick() {
      this.menuOpen = false
    },
    scrollToBottom() {
      this.menuOpen = false
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    }
  },
  computed: {
    socials() {
      return this.$beet.options?.contact?.social || {}
    },
    otherLang() {
      return this.$i18n.locale === 'fr' ? 'EN' : 'FR'
    },
    pdf() {
      if(this.$beet.options?.menus?.menu?.pdfFile) return this.$beet.options?.menus?.menu?.pdfFile?.original || null
      return null
    },
    back() {
      return this.$beet.options?.menus?.menu.backToSite || ''
    }
  }
}
</script>
