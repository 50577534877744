export default {
    title: `2024 carnival program`,
    allTypes: 'All types',
    allPlaces: 'All locations',
    allDates: 'All dates',
    searchActivity: 'Search for an activity',
    noresultTitle: 'No results match your search',
    noresultText: 'Please remove or modify filters or broaden your search.',
    toWatch: 'Get your VIP Access to many of our activities!',
    subscribe: 'Subscribe',
    yourEmail: 'Your email',
    infoTitle: 'Sign up for our newsletter so you don\'t miss out!',
    infoText: 'Receive the latest updates as Carnival approaches and get exclusive information about our partner offers.',
    resultsof: 'results of',
    loadMore: 'Load more activities',
    event: {
        back: 'Back to Activities',
        effigy: 'Effigy Required',
        share: 'Share Activity',
        details: 'Get More Details',
        partners: 'PARTNERS',
        accessibility: 'ACCESSIBILITY',
        likeAlso: 'You May Also Like',
        discover: 'Discover new and unconventional activities that break the conventions and rediscover your favorite events. For the whole family and with friends, during the day and at night, come celebrate with us!',
        explore: 'Explore all activities'
    },
    gdpr: {
        banner: {
            title: 'Our use of cookies',
            description: 'We use cookies to make this site work and to help us make improvements. Clicking accept will enable us to set recommended analytics cookies.',
            accept: 'ACCEPT COOKIES',
            settings: 'CHANGE COOKIES SETTINGS'
        },

        settings: {
            title: 'Our use of cookies',
            description: 'We use essential cookies to make our site work. We\'d also like to set optional analytics cookies to help us improve it. We won\'t set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences. For more detailed information about the cookies we use, see our Privacy & Cookies page.',
            essentials: {
                title: 'Essential cookies',
                description: 'Essential cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.',
            },
            analytics: {
                title: 'Analytics cookies',
                description: 'We\'d like to set Google Analytics cookies to help us to improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work, please see our Privacy & Cookies page.',
            },
            save: 'Save and close'
        },
    }
}
