<template>
    <div class="bubble" :class="{'-reverse' : reverse, '-dark' : dark, '-light' : light}">
      <div class="bubble__content">
        <Icon :id="icon" class="bubble__content-icon"/>
        <span class="bubble__content-text"> {{ text }} </span>
      </div>
  </div>

</template>

<script>
export default {
  name: "Bubble",
  props: {
    icon: {
      type: String,
      default: 'calendar'
    },
    text: {
      type: String,
      default: 'Lorem ipsum dolar sit amet, Lorem ipsum dolar sit amet'
    },
    reverse: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    }
  }
}
</script>
