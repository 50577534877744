
<template>
  <div class="mobile-scroll-up" @click="scrollUp">
    <Icon id="arrow" class="mobile-scroll-up__icon"/>
    Haut
  </div>
</template>

<script>
  export default {
    name: 'MobileScrollUp',
    methods: {
      scrollUp() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
</script>
