<template>
  <SiteHeader/>
  <SiteAlert/>
  <MobileScrollUp/>
  <DailyModal/>
  <SiteNav v-if="mq.mdPlus"/>
  <router-view v-slot="{ Component }" style="">
    <transition name="fade-fast" mode="out-in" appear>
      <div :key="$route.matched[0]?.name === 'main' ? 'main' : $route.name" v-if="$beet.isReady" class="app">
        <component :is="Component" />
      </div>
      <div v-else class="app"></div>
    </transition>
  </router-view>
  <SiteFooter/>
  <Gdpr/>
</template>

<script>
import SiteNav from "@/components/SiteNav";
import {useLangRouter} from "@/extensions/langRouter";
import SiteFooter from "@/components/SiteFooter.vue";
import SiteAlert from "@/components/SiteAlert.vue";
import SiteHeader from "@/components/SiteHeader.vue";
import MobileScrollUp from "@/components/MobileScrollUp.vue";
import DailyModal from "@/components/DailyModal.vue";
import Gdpr from "@/components/gdpr/Gdpr.vue";

export default {
  name: 'App',
  inject: ['mq'],
  setup() {
    useLangRouter()
  },
  components: {Gdpr, DailyModal, MobileScrollUp, SiteHeader, SiteAlert, SiteFooter, SiteNav },
  watch: {
    '$i18n.locale': {
      handler: function(to) {
        this.$beet.fetchCoreData(to)
      },
      immediate: true
    },
    $route: {
      handler(to, from = {}) {
        const delay = to.name !== from.name ? 250 : 100
        setTimeout(() => {
          const el = to.hash ? document.querySelector(to.hash) : null
          el ?
            el.scrollIntoView({behavior: 'smooth', block: 'start'}) :
            window.scrollTo({top: 0, behavior: 'smooth'})
        }, delay)
      },
      immediate: true
    }
  }
}
</script>
