<template>
  <div class="banner" v-if="text.length">
    <div class="banner__scroll">
      <span>{{ text }}</span>
    </div>
    <div class="banner__scroll">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerAlert",
  inject: ['mq'],
  computed: {
    text() {
      return this.$beet.options?.message?.banner || ''
    }
  }
}
</script>
